import { template as template_782fab1b766d4d449d34a2a153f03a50 } from "@ember/template-compiler";
const FKLabel = template_782fab1b766d4d449d34a2a153f03a50(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
