import { template as template_515c9d909ebe4d30a79a47e5964ea269 } from "@ember/template-compiler";
const FKControlMenuContainer = template_515c9d909ebe4d30a79a47e5964ea269(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
