import { template as template_947241be3be448db9fbe4986f073316b } from "@ember/template-compiler";
const WelcomeHeader = template_947241be3be448db9fbe4986f073316b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
