import { template as template_3c54c5aa9831473fa3ebf506c4ed54c0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_3c54c5aa9831473fa3ebf506c4ed54c0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
