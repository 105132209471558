import { template as template_ef60e58d839341d4940b57b15006fadc } from "@ember/template-compiler";
const FKText = template_ef60e58d839341d4940b57b15006fadc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
